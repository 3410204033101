import * as React from "react"
import Link from "./Link"

const baseLink = "https://www.google.com/maps/search/?api=1"

type Props = {
  coordinates: {
    lat?: number
    lon?: number
  }
  placeId?: string
  className?: string
  children?: React.ReactNode
}

const GoogleMapsLink: React.FC<Props> = ({
  coordinates,
  placeId,
  children,
  className,
}) => {
  if (!coordinates) {
    return <div className={className}>{children}</div>
  }

  let link = `${baseLink}&query=${encodeURIComponent(
    coordinates.lat + "," + coordinates.lon
  )}`

  if (placeId) {
    link += `&query_place_id=${encodeURIComponent(placeId)}`
  }

  return (
    <Link className={className} href={link} target="_blank">
      {children}
    </Link>
  )
}

export default GoogleMapsLink
