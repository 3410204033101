import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import Link from "../Link"
import Logo from "./Logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding } from "@fortawesome/free-regular-svg-icons/faBuilding"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import TelLink from "../TelLink"
import GoogleMapsLink from "../GoogleMapsLink"
import Social from "./Social"

const query = graphql`
  query FooterLinks {
    contentfulNavigation(name: { eq: "Footer" }) {
      links {
        id
        label
        url
        page {
          url
        }
        target
      }
    }
    contentfulAddress(name: { eq: "Escritório" }) {
      city
      coordinates {
        lat
        lon
      }
      municipality
      postalCode
      street
    }
    contentfulContact(name: { eq: "Escritório" }) {
      phone
    }
  }
`

const Footer: React.FC = () => {
  const { contentfulNavigation, contentfulAddress, contentfulContact } =
    useStaticQuery<Queries.FooterLinksQuery>(query)

  const links = contentfulNavigation?.links?.map(x => (
    <Link
      key={x.id}
      href={x.page?.url || x.url}
      target={x.target}
      className="hover:underline focus:underline py-4 md:py-0"
    >
      {x.label}
    </Link>
  ))

  return (
    <footer className="py-4 px-4 lg:px-8 flex flex-col-reverse md:flex-row text-gray-100 bg-gray-600">
      <div className="flex flex-col mr-0 mt-5 md:mt-0 md:mr-3 items-center justify-center">
        <div>
          <Logo className="w-14 md:w-20" white={true}></Logo>
        </div>
        <div className="text-xs text-center grow-1">
          Assembleia de Deus de Almada &#169; 2022
        </div>
      </div>

      <div className="grow flex flex-col-reverse space-y-5 space-y-reverse items-center md:flex-row md:justify-between md:space-y-0 md:space-x-5">
        <div className="font-normal space-y-2 text-center md:text-left md:mt-0 md:ml-10 md:order-2">
          <div className="font-semibold">
            <FontAwesomeIcon
              icon={faBuilding}
              className="mr-1"
            ></FontAwesomeIcon>
            Escritório
          </div>
          <address className="not-italic text-xs md:text-sm space-y-2">
            <GoogleMapsLink coordinates={contentfulAddress.coordinates}>
              {contentfulAddress.street}
              <br />
              {contentfulAddress.postalCode} {contentfulAddress.municipality}
            </GoogleMapsLink>
            <div>
              <TelLink phone={contentfulContact.phone}>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="mr-1"
                ></FontAwesomeIcon>
              </TelLink>
            </div>
          </address>
        </div>

        <div className="flex-1 flex flex-col justify-center items-center">
          <Social></Social>
          <div className="mt-4 flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-4 text-sm text-center">
            {links}
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
