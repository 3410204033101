import * as React from "react"
import Link from "./Link"
import parsePhoneNumber from "libphonenumber-js"

type Props = {
  phone: string
  className?: string,
  children?: React.ReactNode
}

const TelLink: React.FC<Props> = ({ phone, className, children }) => {
  const parsedPhone = parsePhoneNumber(phone)

  if (!parsedPhone) {
    return <>{children}</>
  }

  return (
    <Link className={className} href={parsedPhone.getURI()}>
      {children}
      {parsedPhone.formatNational()}
    </Link>
  )
}

export default TelLink
