import * as React from "react"
import { Link as GatsbyLink } from "gatsby"

type Props = {
  href?: string | null
  target?: string
  className?: string,
  children?: React.ReactNode
}

const Link: React.FC<Props> = ({ children, href, target, className }) => {
  const external = href?.includes("//") || href?.startsWith('tel:')
  let link = href  || ""

  link = (!external && !link.startsWith("/")) ? '/' + link  : link

  if (external) {
    return (
      <a
        target={target}
        rel="noopener noreferrer"
        href={href || ''}
        className={className}
      >
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={link} className={className}>
      {children}
    </GatsbyLink>
  )
}

export default Link
