import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import Link from "../Link"

type Props = {
  className?: string
}

const Social: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={"flex text-4xl space-x-12 lg:space-x-16".concat(
        ` ${className}`
      )}
    >
      <Link href="https://www.facebook.com/igreja.adalmada" target="_blank">
        <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
      </Link>
      <Link href="https://www.youtube.com/user/adalmadatv" target="_blank">
        <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
      </Link>
      <Link href="https://www.instagram.com/igreja.adalmada/" target="_blank">
        <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
      </Link>
    </div>
  )
}

export default Social
