import {
  createReactClient,
  LivepeerConfig,
  studioProvider,
  ThemeConfig,
} from '@livepeer/react'
import { AnimatePresence } from 'framer-motion'
import { PageProps } from 'gatsby'
import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Layout from './Layout'

const queryClient = new QueryClient()

const client = createReactClient({
  provider: studioProvider({
    baseUrl: process.env.GATSBY_LIVEPEER_API
  }),
})

const theme: ThemeConfig = {
  colors: {
    accent: '#5CA9CA',
  },
}

const LayoutSelector: React.FC<PageProps<never, { layout: string }>> = ({
  children,
  pageContext,
}) => {
  if (pageContext.layout === 'no-frame') {
    return (
      <LivepeerConfig client={client} theme={theme}>
        {children}{' '}
      </LivepeerConfig>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <LivepeerConfig client={client} theme={theme}>
        <AnimatePresence mode="wait">
          <Layout>{children}</Layout>
        </AnimatePresence>
      </LivepeerConfig>
    </QueryClientProvider>
  )
}

export default LayoutSelector
