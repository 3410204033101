import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import Link from "../Link"
import Logo from "./Logo"
import Social from "./Social"

const query = graphql`
  query HeaderLinks {
    top: contentfulNavigation(name: { eq: "Header - Top" }) {
      links {
        id
        label
        url
        page {
          url
        }
        target
      }
    }
    sidebar: contentfulNavigation(name: { eq: "Header - Sidebar" }) {
      links {
        id
        label
        url
        page {
          url
        }
        target
      }
    }
  }
`

const Header: React.FC = () => {
  const { top, sidebar } = useStaticQuery<Queries.HeaderLinksQuery>(query)

  const topLinks = top.links.map(x => (
    <Link
      key={x.id}
      href={x.page?.url || x.url}
      target={x.target}
      className="flex text-lg items-center font-bold rounded btn-brand px-2"
    >
      {x.label}
    </Link>
  ))

  const sideBarLinks = sidebar.links.map(x => (
    <Link
      key={x.id}
      href={x.page?.url || x.url}
      target={x.target}
      className="text-xl block text-center font-bold rounded btn-brand p-2"
    >
      {x.label}
    </Link>
  ))

  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <header className="fixed top-0 w-full h-16 sm:h-20 px-4 lg:px-8 z-50 bg-white shadow-md">
        <nav className="flex items-center justify-between h-full w-full">
          <Link href="/" className="flex items-center">
            <Logo className="w-16 sm:w-24 ml-2 mr-5 shrink-0"></Logo>
            <div className="text-brand">
              <div className="flex text-2xl sm:text-4xl select-none">
                <span className="font-normal mr-0.5">AD</span>
                <span className="font-thin">ALMADA</span>
              </div>

              <div className="hidden text-xs font-light sm:block lg:hidden xl:block">
                Igreja Evangélica Assembleia de Deus Pentecostal
              </div>
            </div>
          </Link>

          <div className="flex grow lg:max-w-xl xl:max-w-2xl items-center justify-end space-x-8">
            <div className="grow hidden lg:flex justify-between items-center">
              {topLinks}
            </div>

            <button
              aria-label="menu"
              className="w-10 h-10 shrink-0 rounded btn-brand"
              onClick={toggle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {!isOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
            </button>
          </div>
        </nav>
      </header>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.nav
              onClick={toggle}
              initial={{ x: 672 }}
              animate={{ x: 0 }}
              exit={{ x: 672 }}
              transition={{ stiffness: 100 }}
              className="fixed z-40 top-16 sm:top-20 right-0 shadow-lg w-full h-[calc(100vh-64px)] sm:h-[calc(100vh-80px)] lg:max-w-2xl overflow-hidden"
            >
              <div className="flex flex-col justify-between px-4 pt-4 pb-8 h-full bg-gray-50 overflow-auto">
                <div className="space-y-4 md:space-y-6">{sideBarLinks}</div>

                <Social className="justify-center text-brand mt-4 md:mt-6"></Social>
              </div>
            </motion.nav>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="hidden mt-16 sm:mt-20 lg:block fixed z-30 inset-0 bg-gray-900"
              onClick={toggle}
            ></motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
