import * as React from "react"
import Footer from "./Footer"
import Header from "./Header"

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50 font-medium;">
      <Header></Header>
      <main
        className="flex flex-col flex-auto mt-16 container mx-auto sm:mt-20 py-4 px-4 sm:py-10 md:max-w-2xl lg:max-w-4xl xl:max-w-5xl"
      >
        {children}
      </main>
      <Footer></Footer>
    </div>
  )
}

export default Layout
